<template>
  <div>
    <h1>Users</h1>
    <div class="row mb-3">
      <div class="col mt-2">Number of users: <UsersLenght /></div>
      <DadminGames class="col text-end" :selectgame="game" @game="setGame" />
    </div>
    <UsersList :game="game" />
  </div>
</template>

<script>
import UsersLenght from "../UsersLenght.vue";
import UsersList from "../UsersList.vue";
import DadminGames from "../DadminGames.vue";

export default {
  name: "PageUsers",
  components: {
    UsersLenght,
    UsersList,
    DadminGames,
  },

  data() {
    return {
      game: "",
    };
  },

  created() {
    // Get game from local storage
    this.getFilter();
  },

  methods: {
    /**
     * Set game and save it to local storage.
     *
     * @param {string} game - The name of the game to set.
     */
    setGame(game) {
      this.game = game;

      // Save game to local storage
      this.setFilter();
    },

    /** Load filter set from local storage */
    getFilter() {
      let usersFilter = localStorage.getItem("usersFilter");
      if (usersFilter) {
        let filter = JSON.parse(usersFilter);
        this.game = filter.game;
      }
    },

    /** Save filter set to local storage */
    setFilter() {
      let filter = {};
      let usersFilter = localStorage.getItem("usersFilter");
      if (usersFilter) {
        // If usersFilter is set, parse it and set the game.
        filter = JSON.parse(usersFilter);
      } else {
        // If usersFilter is not set, create a new object.
        filter = {};
      }
      filter.game = this.game;
      localStorage.setItem("usersFilter", JSON.stringify(filter));
    },
  },
};
</script>
