<template>
  <div v-if="canAdmin">
    <!-- Title -->
    <h3 class="mb-3">Dadmin users</h3>

    <!-- Users list -->
    <div v-for="(user, index) in usersList" :key="index">
      <div v-if="editUserKey == '' && !newUserShow" class="input-group mb-3">
        <!-- User -->
        <div class="form-control" @click="doEditUser(user)">
          {{ index + 1 }}. {{ user.name }}
        </div>

        <!-- Delete button -->
        <button class="btn btn-outline-secondary" @click="doDelUser(user.uid)">
          <i class="bi bi-trash"></i>
        </button>
      </div>

      <!-- Confirm actions -->
      <ActionConfirm
        v-if="confirmKey == user.uid"
        class="mb-3"
        :action="confirmActions[confirm]"
        @confirm="(action) => doConfirm(action, user.uid)"
        @cancel="doCancelConfirm"
      />

      <!-- Edit user form -->
      <EditJson
        class="mb-3"
        v-if="editUserKey == user.uid"
        :edit="{
          json: user,
          form: true,
          formStart: true,
          formTitle: 'Edit user: ' + user.name,
          formReadonlyInputs: ['uid'],
          canSave: true,
          cmdSave: cmdSetConfig + newUserFolder + '/' + user.uid,
        }"
        @save="editUserKey = ''"
        @cancel="editUserKey = ''"
      />
    </div>

    <!-- New user Button -->
    <button
      v-if="editUserKey == '' && !newUserShow"
      class="btn btn-secondary"
      @click="doAddNewUser"
    >
      {{ newUserText }}
    </button>

    <!-- New user Form -->
    <EditJson
      v-if="newUserShow"
      :edit="{
        json: newUser,
        form: true,
        formStart: true,
        formTitle: newUserText,
        formReadonlyInputs: ['uid'],
        canSave: true,
        cmdSave: newUserCmd,
      }"
      @save="doSave"
      @cancel="newUserShow = false"
    />
  </div>
</template>

<script>
import EditJson from "./EditJson.vue";
import ActionConfirm from "./ActionConfirm.vue";

const cmdAccessAdmin = "login.access-admin/DadminLoginList";
const cmdAccessAdminCommon = "login.access-admin";
const cmdLoginUsersList = "login.users.list";
const cmdDelConfig = "config.del/";

export default {
  name: "DadminLoginList",
  components: {
    EditJson,
    ActionConfirm,
  },

  data() {
    return {
      canAdmin: false,
      usersList: [],
      newUser: {
        uid: "",
        name: "",
        email: "",
        access_read: false,
        access_write: false,
        access_admin: false,
        access_devel: false,
      },
      newUserShow: false,
      newUserText: "Add new user",
      newUserFolder: "login",
      newUserCmd: "",
      editUserKey: "",
      cmdSetConfig: "config.set/",

      /** confirm action */
      confirm: "",
      confirmKey: "",
      confirmActions: {
        remove: {
          name: "remove",
          text: "Remove dadmin user?",
          word: "remove",
        },
      },
    };
  },

  mounted: function () {
    let that = this;

    // Set user key to edit in form
    this.userkey = localStorage.userkey;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      // Process answer to commands
      switch (gw.command) {
        case cmdAccessAdmin:
          that.canAdmin = !gw.err;
          if (that.canAdmin) {
            that.teoweb.sendCmd(cmdLoginUsersList);
          }
          break;

        case cmdAccessAdminCommon:
          that.canAdmin = !gw.err;
          break;

        case cmdLoginUsersList:
          if (gw.err) {
            break;
          }
          that.usersList = JSON.parse(data);
          that.usersList.sort((a, b) => (a.name > b.name ? 1 : -1));
          break;

        case that.newUserCmd:
          if (gw.err) {
            break;
          }
          that.teoweb.sendCmd(cmdLoginUsersList);
          break;
      }

      if (gw.command.startsWith(cmdDelConfig)) {
        console.debug("cmdDelConfig", gw.command);
        that.teoweb.sendCmd(cmdLoginUsersList);
      }
    });

    // Send get users list command to WebRTC server
    this.teoweb.whenLogin(function () {
      that.teoweb.sendCmd(cmdAccessAdmin);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** doSave - executes when new user saved */
    doSave: function () {
      this.newUserShow = false;
    },

    /** doEditUser - executes when edited user button clicked */
    doEditUser(user) {
      this.editUserKey = this.editUserKey != "" ? "" : user.uid;
    },

    /** doAddNewUser - executes when add new user button clicked */
    doAddNewUser: function () {
      this.newUser.uid = this.getUid();
      this.newUserCmd =
        this.cmdSetConfig + this.newUserFolder + "/" + this.newUser.uid;
      this.newUserShow = true;
    },

    /** doDelUser - executes when delete user button clicked */
    doDelUser(key) {
      if (!this.confirm) {
        this.confirm = "remove";
        this.confirmKey = key;
        return;
      }
      this.confirm = "";
      this.confirmKey = "";
    },

    /** doConfirm - executes when action confirmed */
    doConfirm(action, key) {
      switch (action) {
        case "remove": {
          this.teoweb.sendCmd(cmdDelConfig + this.newUserFolder + "/" + key);
          this.doCancelConfirm();
          break;
        }
      }
    },

    /** doCancelConfirm - executes when action cancelled */
    doCancelConfirm() {
      this.confirm = "";
      this.confirmKey = "";
    },

    /** getUid - generates and returns random uid  */
    getUid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return (
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    },
  },
};
</script>
