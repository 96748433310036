<template>
  <div class="card">
    <div class="login card-body">
      <div class="accordion accordion-flush" id="accordion">
        <!-- Login key and user config -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              :class="canRead ? 'collapsed' : ''"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Your Login data
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            :class="!canRead ? 'show' : ''"
            aria-labelledby="headingOne"
            data-bs-parent="#accordion"
          >
            <div class="accordion-body p-0 mt-3">
              <!-- Edit login key form -->
              <form class="form-group mb-3" action="javascript:void(0);">
                <!-- Login key input group -->
                <div class="input-group">
                  <!-- Login key label -->
                  <label for="key" class="input-group-text">Key</label>
                  <!-- Login key input -->
                  <input
                    v-model="userkey"
                    class="form-control"
                    :type="userkeyType"
                    placeholder="Enter your login key"
                    required
                    style="padding-right: 42px"
                    autocomplete="password"
                  />
                  <!-- Eye button -->
                  <div v-if="userkey != ''">
                    <button
                      type="button"
                      class="btn btn-secondary- bg-transparent"
                      @click="changeUserkeyType"
                      style="margin-left: -48px; z-index: 100"
                    >
                      <i
                        v-if="this.userkeyType == 'password'"
                        class="bi bi-eye"
                      ></i>
                      <i v-else class="bi bi-eye-slash"></i>
                    </button>
                  </div>
                  <!-- Login button -->
                  <div v-if="checkKey() == 1">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="doSave"
                    >
                      Login
                    </button>
                  </div>
                  <!-- Logout button -->
                  <div v-if="checkKey() == 2">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="doClear"
                    >
                      Logout
                    </button>
                  </div>
                </div>

                <!-- Remember me checkbox -->
                <div v-if="checkKey() == 1" class="p-3">
                  <input
                    class="form-check-input"
                    id="remember"
                    type="checkbox"
                    v-model="remember"
                  />
                  <label class="form-check-label" for="remember"
                    >Remember my key on this device</label
                  >
                </div>
              </form>

              <!-- Edit login config -->
              <EditConfig
                v-if="loggedIn"
                name="Edit login config"
                subject="You login config."
                :config="{ folder: 'login', key: userkey }"
                cantread="Access denied."
                :cantsave="!canAdmin"
                :form="true"
                :formStart="true"
                :formReadonlyInputs="formReadonlyInputs"
                formTitle="Login config"
              />
            </div>
          </div>
        </div>

        <!-- Dadmin users list -->
        <div v-if="canAdmin" class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Dadmin users
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordion"
          >
            <div class="accordion-body p-0 mt-3">
              <DadminLoginList />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditConfig from "./EditConfig.vue";
import DadminLoginList from "./DadminLoginList.vue";

const cmdLoginUserSet = "login.user.set/";

const cmdAccessRead = "login.access-read";
const cmdAccessWrite = "login.access-write";
const cmdAccessAdmin = "login.access-admin";
const cmdAccessDevel = "login.access-devel";

export default {
  name: "DadminLogin",
  components: {
    EditConfig,
    DadminLoginList,
  },

  data() {
    return {
      canRead: false,
      canAdmin: false,
      remember: false,
      loggedIn: false,
      userkey: "",
      userkeyType: "password",
      formReadonlyInputs: ["uid"],
    };
  },

  mounted: function () {
    let that = this;

    // Set user key to edit in form
    this.userkey = localStorage.userkey || sessionStorage.userkey;
    this.loggedIn = sessionStorage.loggedIn == "true";

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw) {
      // Process answer to cmdLoginUserSet command
      if (gw.command.startsWith(cmdLoginUserSet)) {
        that.teoweb.sendCmd(cmdAccessRead);
        that.teoweb.sendCmd(cmdAccessWrite);
        that.teoweb.sendCmd(cmdAccessAdmin);
        that.teoweb.sendCmd(cmdAccessDevel);

        that.loggedIn = !gw.err;
        sessionStorage.loggedIn = that.loggedIn;
      }
      // Process answer to commands
      switch (gw.command) {
        case cmdAccessRead:
          that.canRead = !gw.err;
          break;
        case cmdAccessAdmin:
          that.canAdmin = !gw.err;
          if (!that.canAdmin) {
            that.formReadonlyInputs = [
              "uid",
              "access_admin",
              "access_devel",
              "access_read",
              "access_write",
              "email",
            ];
          } else {
            that.formReadonlyInputs = ["uid"];
          }
          break;
      }
    });

    // Send get users list command to WebRTC server
    this.teoweb.whenLogin(function () {
      that.teoweb.sendCmd(cmdAccessAdmin);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /**
     * Saves the user key to local storage and logs it.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    doSave() {
      // Save user key
      let userkey = this.userkey;
      if (this.remember) {
        localStorage.userkey = userkey;
      }
      sessionStorage.userkey = userkey;

      // Update user key and set user in setTimeout to switch buttons in form
      this.userkey = "";
      this.canAdmin = false;
      setTimeout(() => {
        this.userkey = userkey;
        this.teoweb.sendCmd(cmdLoginUserSet + userkey);
      });
    },

    /**
     * Clears the user key from local storage and sets the component's userkey
     * to an empty string.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    doClear() {
      sessionStorage.loggedIn = false;
      sessionStorage.userkey = "";
      localStorage.userkey = "";
      this.teoweb.token = null;
      this.loggedIn = false;
      this.canAdmin = false;
      this.userkey = "";

      // Set user
      this.teoweb.sendCmd(cmdLoginUserSet + this.userkey);
    },

    /**
     * Checks whether the user key is valid and determines which buttons
     * should be shown.
     *
     * @return {Number}
     */
    checkKey() {
      // Return 0 - If key is empty and not logged in than does not show login
      // or logout buttons
      if (!this.userkey && !this.loggedIn) {
        return 0;
      }

      // Return 1 - Show Login button
      if (!this.loggedIn && this.userkey) {
        return 1;
      }

      // Return 2 - Show Logout button
      return 2;
    },

    /**
     * Toggles the type of the userkey input field between "password" and "text".
     *
     * @return {void}
     */
    changeUserkeyType() {
      this.userkeyType = this.userkeyType == "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
input.btn.btn-primary {
  margin-top: 10px;
}
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-bg);
}
.form-check-input {
  margin-right: 10px;
}
</style>
