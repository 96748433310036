<template>
  <div class="hello">
    <h1>Login</h1>
    <div class="description text-secondary mb-3">
      Dastland games administrator login page.
    </div>
    <DadminLogin />
    <TeoInfo />
  </div>
</template>

<script>
import TeoInfo from "../TeoInfo.vue";
import DadminLogin from "../DadminLogin.vue";

export default {
  name: "PageHome",
  components: {
    TeoInfo,
    DadminLogin,
  },

  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.description {
  font-size: small;
}
</style>
