<template>
  <!-- Confirm action -->
  <div v-if="action" class="form-group">
    <div class="">
      <div class="mb-2">{{ action.text }}</div>
      <div v-if="action.word">
        <label class="form-label description"
          >To confirm this action, enter the text '{{ action.word }}' and click
          the Confirm button:
        </label>
        <input
          class="form-control"
          v-model="confirmWord"
          :placeholder="action.word"
          type="text"
        />
      </div>
      <div v-else class="description">Please confirm your action:</div>
    </div>
    <input
      type="button"
      class="btn btn-danger btn-sm mt-2 mr-2"
      value="Confirm"
      :disabled="action.word != confirmWord"
      @click="doConfirm"
    />
    &nbsp;
    <input
      type="button"
      class="btn btn-secondary btn-sm mt-2"
      value="Cancel"
      @click="doCancel"
    />
  </div>
</template>

<script>
export default {
  name: "ActionConfirm",

  props: {
    action: Object,
  },

  data: function () {
    return {
      confirmWord: "",
    };
  },

  methods: {
    // Confirm action.
    doConfirm() {
      this.$emit("confirm", this.action.name);
    },

    // Cancel action.
    doCancel() {
      this.$emit("cancel");
    },
  },

  watch: {
    // Clear confirm word when action changes to empty string (closed).
    action: function (value) {
      if (!value) {
        this.confirmWord = "";
      }
    },
  },
};
</script>

<style scoped>
.description {
  font-size: small;
  margin-bottom: 10px;
}
</style>
