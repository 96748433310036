<template>
  <div class="config-edit body">
    <!-- Edit or Copy -->
    <div v-if="!edit.confirm">
      <!-- Switch edit form or json button -->
      <div v-if="edit.form && !edit.nobuttons" class="input-group">
        <div class="form-control label">
          {{ edit.formTitle }}
        </div>
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm"
          @click="switchForm"
        >
          {{ !editForm ? "Form" : "Json" }}
        </button>
        <button
          type="button"
          class="input-group-text btn btn-outline-secondary"
          aria-label="Close"
          @click="doCancel"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Json as text -->
      <div v-if="!editForm" class="form-group mb-2">
        <!-- Edit mode header -->
        <div v-if="!edit.copy">
          <!-- Name: {{ name }} -->
          <div v-for="param in params" :key="param.name">
            {{ param.name }}: {{ param.value }}
          </div>
        </div>

        <!-- Copy mode header -->
        <div v-else>
          New name:
          <input class="form-control" v-model="name" placeholder="Enter name" />
          <div v-for="(param, i) in params" :key="param.name">
            New {{ param.name }}:
            <input
              class="form-control"
              v-model="params[i].value"
              placeholder="Enter name"
            />
          </div>
        </div>

        <!-- Json: -->
        <textarea
          class="form-control"
          name="body"
          v-model="jsonStr"
          :rows="countLines()"
        />

        <div v-show="err" class="error">Error: {{ err }}</div>

        <!--  {{ if .Saved }}
        <div class="saved">Saved</div>
        {{ end }} -->
      </div>

      <!-- Json as form -->
      <div v-else class="mb-2">
        <!-- This json form represents: -->
        <form>
          <div
            v-for="(v, i) in toArray(form)"
            :key="i"
            class="border rounded p-3"
          >
            <div v-for="(value, name) in v" :key="name" class="mb-3">
              <div v-if="filter(name)">
                <!-- Show object or array -->
                <div v-if="Array.isArray(value) || typeof value === 'object'">
                  <label class="form-label" :for="name"
                    ><span v-if="toArray(form).length > 1">{{ i + 1 }}. </span
                    >{{ name }}</label
                  >
                  <EditJson
                    :edit="{
                      name: 'name',
                      form: true,
                      formStart: true,
                      formTitle: name,
                      json: value,
                      nobuttons: true,
                      formFilterNames: edit.formFilterNames,
                    }"
                  />
                </div>

                <!-- Show element -->
                <div v-else>
                  <!-- Text label -->
                  <label v-if="!isBool(value)" class="form-label" :for="name">{{
                    name
                  }}</label>

                  <!-- Text or Checkbox input -->
                  <input
                    :type="isBool(value) ? 'checkbox' : 'text'"
                    :class="isBool(value) ? 'form-check-input' : 'form-control'"
                    :id="name"
                    :aria-describedby="name + 'Help'"
                    :placeholder="name"
                    :readonly="isReadonly(name)"
                    :disabled="isReadonly(name)"
                    v-model="v[name]"
                  />

                  <!-- Checkbox label -->
                  <label
                    v-if="isBool(value)"
                    class="form-check-label"
                    :for="name"
                    >{{ name }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Buttons -->
      <div v-if="!edit.nobuttons" class="form-group">
        <input
          type="button"
          class="btn btn-secondary"
          @click="doCancel"
          value="Cancel"
        />
        <input
          type="submit"
          class="btn btn-primary float-end"
          :class="{ disabled: !edit.canSave }"
          @click="doSave"
          value="Save"
        />
      </div>
    </div>

    <!-- Confirm -->
    <div v-else class="form-group">
      <div class="mt-2">Please confirm your {{ edit.confirm }} action:</div>
      <input
        type="button"
        class="btn btn-primary btn-sm mt-2"
        value="Yes"
        @click="doConfirm"
      />
      <input
        type="button"
        class="btn btn-secondary btn-sm mt-2"
        value="No"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "EditJson",
  props: {
    edit: {},
  },
  emits: ["save", "cancel"],
  data() {
    return {
      jsonStr: "", // JSON string used in form
      err: "", // Error message to show JSON.Parse errors
      name: this.edit.name, // Copy of edit.name prop
      params: this.edit.params, // Copy of edit.params.prop
      form: {}, // Json Form map
      editForm: this.edit.form && this.edit.formStart, // Allow edit form or json
    };
  },
  mounted: function () {
    if (this.edit.json) {
      this.jsonStr = JSON.stringify(this.edit.json, null, 2);

      // Print json fields to console
      if (this.edit.form) {
        this.form = this.edit.json;
      }
    }
  },
  unmounted: function () {
    if (this.reader) {
      this.teoweb.delReader(this.reader);
    }
  },
  methods: {
    /** Return true if name is not in filterNames */
    filter(name) {
      if (this.edit.formFilterNames) {
        if (this.edit.formFilterNames.includes(name)) {
          return false;
        }
      }
      return true;
    },

    /** Convert object to array of objects */
    toArray(value) {
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    },

    /** Emit cancel event */
    doCancel() {
      this.$emit("cancel");
    },

    /** Pare json to input object end emit save event */
    doSave() {
      if (this.editForm) {
        this.jsonStr = JSON.stringify(this.form, null, 2);
      }

      // Parse json to check errors
      let json = "";
      try {
        json = JSON.parse(this.jsonStr);
      } catch (e) {
        this.err = e; // error in the above string (in this case, yes)!
        return;
      }

      // Stringify and Save json
      let jsonStr = JSON.stringify(json, null, 2);

      // Add save parameters
      let params = "";
      if (this.params) {
        this.params.forEach((p) => (params = params + "/" + p.value));
      }

      // Send save command
      this.teoweb.sendCmd(this.edit.cmdSave + params, jsonStr);

      // Emit event "save"
      this.$emit("save");
    },

    /** Pare json to input object end emit save event */
    doConfirm() {
      // Add confirm parameters
      let params = "";
      if (this.params) {
        this.params.forEach((p) => (params = params + "/" + p.value));
      }

      // Define confirm command
      let that = this;
      let cmdConfirm = this.edit.cmdConfirm + "/" + this.name + params;
      console.debug("confirm", this.edit.confirm, "command:", cmdConfirm);

      // Add 'reader' which will receive data from WebRTC Data Channel
      this.reader = this.teoweb.addReader(function (gw) {
        console.debug("doConfirm got", gw);
        if (gw.err) {
          return;
        }
        switch (gw.command) {
          case cmdConfirm:
            that.teoweb.delReader(that.reader);
            that.$emit("save");
            break;
        }
      });

      // Send confirm command
      this.teoweb.sendCmd(cmdConfirm);
    },

    /** Check if value is boolean */
    isBool(value) {
      return value === true || value === false;
    },

    /** Switch edit form or json */
    switchForm() {
      if (this.editForm) {
        this.jsonStr = JSON.stringify(this.form, null, 2);
      } else {
        this.form = JSON.parse(this.jsonStr);
      }
      this.editForm = !this.editForm;
    },

    /** Calculates  number of lines in json */
    countLines() {
      return this.jsonStr.split("\n").length;
    },

    /** Check if name is readonly */
    isReadonly(name) {
      if (!this.edit.formReadonlyInputs) {
        return false;
      }

      return this.edit.formReadonlyInputs.includes(name);
    },
  },
};
</script>

<style scoped>
.body .error {
  color: red;
}

.body .saved {
  color: green;
}

.form-check-input {
  margin-right: 10px;
}
</style>
