<template>
  <!-- Help -->
  <div id="filterInventoryHelp" class="form-text">
    <!-- Help subject -->
    <div
      data-bs-toggle="collapse"
      data-bs-target="#collapseHelp"
      aria-expanded="false"
      aria-controls="collapseHelp"
    >
      Filter by players who played the game. Select the type from the drop-down
      list and enter the item name, sign and quantity.

      <!-- Help button -->
      <div class="badge rounded-pill border text-secondary" type="button">
        Help
      </div>
    </div>

    <!-- Help collapse content -->
    <div class="collapse mt-3" :class="show ? 'show' : ''" id="collapseHelp">
      <!-- Card -->
      <div class="card">
        <!-- Header -->
        <div class="card-header">
          <div class="row">
            <!-- Title -->
            <h3 class="col">Filter help</h3>
            <!-- Close button -->
            <div class="col p-2 text-end">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                data-bs-toggle="collapse"
                data-bs-target="#collapseHelp"
              ></button>
            </div>
          </div>
        </div>
        <!-- Body -->
        <div class="card-body">
          <!-- Find in Items -->
          <div v-if="checkType('Items')">
            <h5>Find in Items storage:</h5>
            <!-- Example 1 -->
            <div class="mb-3 mt-3">
              1. Find players holding the item Bolts with quantity >= 1000:<br />
              <span class="text-success p-2">Bolts amount >= 1000</span>
              <div class="pl-2">or</div>
              <span class="text-success p-2">Bolts >= 1000</span>
            </div>
            <!-- Example 2 -->
            <div class="mb-3">
              2. Find players containing the Bolts element:<br />
              <span class="text-success p-2">Bolts</span>
            </div>
          </div>

          <!-- Find in Armor -->
          <div v-if="checkType('Armor')">
            <h5>Find Armor on a player:</h5>
            <!-- Example 1 -->
            <div class="mb-3 mt-3">
              1. Find a piece of armor equipped on a player by type and name<br />
              <span class="text-success p-2">savedHelmet MaraudersHelmet</span>
            </div>
            <!-- Example 2 -->
            <div class="mb-3 mt-3">
              2. Find a piece of armor equipped on a player by type<br />
              <span class="text-success p-2">savedHelmet</span>
            </div>
          </div>

          <!-- Find in Storage -->
          <div v-if="checkType('Storage')">
            <h5>Find in Storage:</h5>
            <!-- Example 1 -->
            <div class="mb-3 mt-3">
              1. Find players who have WomenMaraudersShirt in their chest with
              quantity > 0:<br />
              <span class="text-success p-2"
                >WomenMaraudersShirt amount > 0</span
              >
              <div class="pl-2">or</div>
              <span class="text-success p-2">WomenMaraudersShirt > 0</span>
            </div>
            <!-- Example 2 -->
            <div class="mb-3">
              2. Find players containing the WomenMaraudersShirt element in
              their chest:<br />
              <span class="text-success p-2">WomenMaraudersShirt</span>
            </div>
          </div>

          <!-- Find All -->
          <div v-if="checkType('All')">
            <h5>Find in Items, Armor or Storage:</h5>
            <!-- Example 1 -->
            <div class="mb-3 mt-3">
              Example 1<br />
              <div class="text-success p-2">
                (Items.Bolts.amount > 20 and Items.Bolts.durability > 50 and
                Items.Medical_Kit_60 != nil) and<br />
                (Armor.savedShirtWithGloves.ItemId == 'WomenLiquiformJacket' and
                Armor.savedShirtWithGloves.durability > 50)
              </div>
            </div>
            <!-- Example 2 -->
            <div class="mb-3 mt-3">
              Example 2<br />
              <span class="text-success p-2"
                >Armor.savedHelmet != nil && Items.Bolts.amount > 500</span
              >
            </div>
            <!-- Example 3 -->
            <div class="mb-3 mt-3">
              Example 3<br />
              <span class="text-success p-2"
                >Items.Bolts.amount in [1000, 2000]</span
              >
            </div>
            <!-- Variables -->
            <div class="mb-3 mt-3">
              Avalaiable variables in expressions:<br />
              <br />
              <!-- Items -->
              To find in Items:<br />
              <div class="text-success p-2">
                Items.ItemId, Items.ItemId.amount, Items.ItemId.durability -
                where ItemId is item name
              </div>
              <br />
              <!-- Armor -->
              To find in Armor:<br />
              <div class="text-success p-2">
                Armor.ContainerId, Armor.ContainerId.ItemId,
                Armor.ContainerId.durability - where ContainerId is container
                name and ItemId is item name
              </div>
              <br />
              <!-- Storage -->
              To find in Storage:<br />
              <div class="text-success p-2">
                Storage.ItemId, Storage.ItemId.amount, Storage.ItemId.durability
                - where ItemId is item name
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersFilterHelp",

  props: {
    type: String,
  },

  data() {
    return {
      show: true,
    };
  },

  mounted() {
    // Get help show state from local storage
    this.getHelpShow();
  },

  beforeUnmount() {
    // Save help show state to local storage
    this.setHelpShow();
  },

  methods: {
    /** Checks filter type selected and returns true or false */
    checkType(type) {
      if (this.type == type) {
        return true;
      }
      return false;
    },

    /** Get help show state from local storage */
    getHelpShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      if (usersFilterStr) {
        let usersFilter = JSON.parse(usersFilterStr);
        if (usersFilter.help === true || usersFilter.help !== false) {
          this.show = true;
        } else {
          this.show = false;
        }
      }
    },

    /** Save help show state to local storage */
    setHelpShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      let usersFilter = { help: this.show };
      if (usersFilterStr) {
        usersFilter = JSON.parse(usersFilterStr);
      }

      const collapseHelpClasses = document.getElementById("collapseHelp").classList;
      usersFilter.help = collapseHelpClasses.contains("show");

      localStorage.setItem("usersFilter", JSON.stringify(usersFilter));
    },
  },
};
</script>

<style scoped>
.pl-2 {
  padding-left: 8px;
}
</style>
