<template>
  <div v-if="canRead" class="">
    <button
      class="btn btn-sm dropdown-toggle"
      :class="
        width + ' ' + (outline ? 'btn-outline-secondary' : 'btn-secondary')
      "
      type="button"
      @click="doShow"
    >
      {{ name }}
    </button>

    <div v-if="subject" class="subject" :class="width">
      {{ subject }}
    </div>

    <div v-if="showForm">
      <EditJson :edit="edit" @save="doSave" @cancel="doShow" />
    </div>
  </div>
  <div v-else class="text-danger">{{ cantread }}</div>
</template>

<script>
import EditJson from "./EditJson.vue";

const cmdGetConfig = "config.get/";
const cmdSetConfig = "config.set/";
const cmdAccessRead = "login.access-read";
const cmdAccessWrite = "login.access-write";

export default {
  name: "EditConfig",
  components: {
    EditJson,
  },
  props: {
    name: String,
    subject: String,
    config: Object,
    outline: Boolean,
    width: String,
    cantread: String, // Can't read Text
    cantsave: Boolean, // Allow write if cantsave is true or if access-admin is granted
    form: Boolean, // Allow edit form
    formStart: Boolean, // Show form on start
    formTitle: String, // Form title
    formReadonlyInputs: Array, // Form readonly fields
  },
  data() {
    return {
      showForm: false,
      canRead: false,
      edit: {
        json: {},
        form: this.form,
        formStart: this.formStart,
        formTitle: this.formTitle,
        formReadonlyInputs: this.formReadonlyInputs,
      },
    };
  },

  mounted: function () {
    let that = this;

    // TODO: remove this when cmdAccessWrite will be implemented
    // this.edit.canRead = true;
    this.edit.cmdSave =
      cmdSetConfig + this.config.folder + "/" + this.config.key;

    this.reader = this.teoweb.addReader(function (gw, data) {
      switch (gw.command) {
        case cmdAccessRead:
          that.canRead = !gw.err;
          break;

        case cmdAccessWrite:
          if (!that.cantsave) {
            that.edit.canSave = !gw.err;
          }
          break;

        case cmdGetConfig + that.config.folder + "/" + that.config.key: {
          let jsonStr = "" + data;
          if (gw.err) {
            that.error = "Error: " + gw.err;
            break;
          }

          that.edit.json = JSON.parse(jsonStr);
          that.showForm = true;
          break;
        }
      }
    });

    // Send cmdAccessWrite amd cmdMaps commands to WebRTC server when connected
    this.teoweb.whenConnected(() => {
      this.teoweb.sendCmd(cmdAccessRead);
      this.teoweb.sendCmd(cmdAccessWrite);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** do when show or canceled */
    doShow() {
      if (!this.showForm) {
        this.teoweb.sendCmd(
          cmdGetConfig + this.config.folder + "/" + this.config.key
        );
        return;
      }
      this.showForm = false;
    },

    /** do when saved */
    doSave() {
      this.showForm = false;
    },
  },

  watch: {
    // Update edit.cmdSave when config property changes.
    config: function () {
      this.edit.cmdSave =
        cmdSetConfig + this.config.folder + "/" + this.config.key;
    },

    // Update edit.formReadonlyInputs when formReadonlyInputs property changes.
    formReadonlyInputs: function () {
      this.edit.formReadonlyInputs = this.formReadonlyInputs;
    },

    // Request access-write when cantsave property changes.
    // cantsave: function () {
    //   this.teoweb.sendCmd(cmdAccessWrite);
    // },
  },
};
</script>

<style scoped>
.subject {
  color: gray;
  font-size: small;
  margin-bottom: 15px;
}
.config-edit {
  margin-bottom: 25px;
}
</style>
