<template>
  <!-- List of actions -->
  <div>
    <!-- Title -->
    <div v-if="!groupId">
      <h1 class="mb-0">Actions</h1>
      <small class="text-secondary mb-3">This is a list of actions.</small>
    </div>
    <div v-else >
      <ActionGroup :action="actions.group" :top="true" />
      <small class="text-secondary mb-3">This is a list of actions in action group.</small> 
    </div>

    <!-- Error message -->
    <div v-if="error" class="alert alert-danger mt-3" role="alert">
      {{ error }}
    </div>

    <!-- List of actions -->
    <div v-else v-for="(action, index) in actions.rows" :key="index">
      <hr />
      <div class="mb-0">
        <h5 class="mb-0">{{ index+1 }}. {{ action.UserName }}</h5>
        <div><small>{{ action.Action }}</small></div>
        <div v-if="!groupId" class="mb-2 description text-secondary">
          Group id: {{ action.GroupId }}<br />
        </div>
      </div>
      <div class="description">
        <div>Set {{ action.State.replace(", error: <nil>", "") }}</div>
      </div>
    </div>

    <!-- Next page button -->
    <div v-if="previous && !error" class="text-center">
      <hr />
      <a
        class="btn btn-secondary"
        :class="processGetPage ? 'disabled' : ''"
        @click="getNextPage()"
      >
        <i v-if="!processGetPage" class="bi bi-box-arrow-down"></i>
        <i v-else class="bi bi-arrow-clockwise"></i>
      </a>
    </div>

  </div>
</template>

<script>
import ActionGroup from "./ActionGroup.vue";

const cmdJournalList = "journal.list.actions/";

export default {
  name: "ActionView",

  components: {
    ActionGroup,
  },

  data() {
    return {
      currentPath: window.location.hash,
      groupId: "",
      actions: { rows : [], group: {} },
      processGetPage: false,
      previous: "",
      error: "",
    };
  },

  mounted() {
    let that = this;

    // Get url parameters
    let parameters = this.currentPath.slice(2).split("/").slice(1);
    this.groupId = parameters[0] || "";

    // Add reader
    this.reader = this.teoweb.addReader(function (gw, data) {
      switch (gw.command) {
        // Process answer to cmdJournalList command
        case cmdJournalList + that.previous + "/" + that.groupId: {
          that.processGetPage = false;

          that.error = "";
          if (gw.err) {
            that.error = "Error: " + gw.err;
            return;
          }
          that.error = "";

          let actions = JSON.parse(data);
          that.previous = actions.pagination;
          that.actions.group = actions.group;
          if (actions.rows) {
            that.actions.rows = that.actions.rows.concat(actions.rows);
          } else {
            that.previous = "";
          }
          
          console.debug("got journal list", actions);
          break;
        }
      }
    });

    // Check list scroll to bottom
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight + 300 >= scrollHeight) {
        if (that.previous && !that.error && !that.processGetPage) {
          that.getNextPage();
        }
      }
    });

    // Send command when login
    this.teoweb.whenLogin(function () {
      that.getNextPage();
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
    window.removeEventListener("scroll", () => {});
  },

  methods: {
    getNextPage() {
      this.processGetPage = true;
      this.teoweb.sendCmd(cmdJournalList + this.previous + "/" + this.groupId);
    },
  },
};
</script>
