<template>
  <div>
    <!-- Users list filter -->
    <UsersFilter
      v-if="allGames()"
      @filter="setFilter"
      aria-describedby="filterHelp"
    />

    <!-- Users list Inventory filter -->
    <div v-else>
      <UsersFilterInventory
        :game="game"
        @filter="setFilter"
        aria-describedby="filterInventoryHelp"
      />
    </div>

    <!-- Users list -->
    <div v-if="!error">
      <div v-for="(user, index) in usersList" :key="index">
        <hr />

        <!-- User name -->
        <h5>{{ index + 1 }}. {{ user.Username }}</h5>

        <!-- User attributes -->
        <div class="description">
          <div>Id: {{ AttributesMap(user.Attributes)["sub"] }}</div>
          <div>Email: {{ AttributesMap(user.Attributes)["email"] }}</div>
          <div>
            Email verified:
            {{
              AttributesMap(user.Attributes)["email_verified"] == "true"
                ? "Yes"
                : "No"
            }}
          </div>
          <PlayerOnline
            :game="game"
            :sub="AttributesMap(user.Attributes)['sub']"
          />
        </div>

        <!-- Users list actions -->
        <UsersListActions
          :game="game"
          :sub="AttributesMap(user.Attributes)['sub']"
        />
      </div>
    </div>
    <div v-else class="text-danger">An error occurred: {{ error }}</div>

    <!-- Next page button -->
    <div v-if="previous && !error" class="text-center">
      <hr />
      <a
        class="btn btn-secondary"
        :class="processGetPage ? 'disabled' : ''"
        @click="getNextPage(filter)"
      >
        <i v-if="!processGetPage" class="bi bi-box-arrow-down"></i>
        <i v-else class="bi bi-arrow-clockwise"></i>
      </a>
    </div>
  </div>
</template>

<script>
import PlayerOnline from "./PlayerOnline.vue";
import UsersFilter from "./UsersFilter.vue";
import UsersFilterInventory from "./UsersFilterInventory.vue";
import UsersListActions from "./UsersListActions.vue";

const allGames = "All games";
const cmdUsersList = "users.list/";

export default {
  name: "UsersList",
  components: {
    UsersFilter,
    UsersFilterInventory,
    UsersListActions,
    PlayerOnline,
  },

  props: {
    game: String,
  },

  data() {
    return {
      error: "",
      usersList: [],
      previous: "",
      filter: "",
      gamePrev: "",
      processGetPage: false,
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      // Process answer to cmdUsersList command
      if (gw.command.startsWith(cmdUsersList)) {
        that.processGetPage = false;

        if (gw.err) {
          that.error = gw.err;
          return;
        }
        that.error = "";

        let answer = JSON.parse(data);
        that.previous = answer.Pagination;
        if (answer.Users) {
          that.usersList = that.usersList.concat(answer.Users);
        }
      }
    });

    // Check list scroll to bottom
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop + clientHeight + 300 >= scrollHeight) {
        if (that.previous && !that.error && !that.processGetPage) {
          that.getNextPage(that.filter);
        }
      }
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
    window.removeEventListener("scroll", () => {});
  },

  methods: {
    /**
     * getNextPage - Sends a command to the WebRTC server to get the next
     * page of users.
     */
    getNextPage(filter, refresh = false) {
      if (refresh) {
        this.usersList = [];
        this.previous = "";
      }
      this.processGetPage = true;
      this.sendCmdUsersList(filter);
    },

    /**
     * sendCmdUsersList - Sends a command to the WebRTC server to get the next
     * page of users.
     */
    sendCmdUsersList(filter = "") {
      this.teoweb.sendCmd(
        cmdUsersList + this.previous + "/" + this.game,
        filter
      );
    },

    /**
     * setFilter sets filter and filterType and call getNextPage.
     * @param type
     * @param filter
     */
    setFilter(filter) {
      this.filter = filter;
      this.getNextPage(filter, true);
    },

    /**
     * AttributesMap - Returns a map of attributes created from an array of
     * attribute objects. Each attribute object has two properties: Name and
     * Value. The function loops through the array and creates a new property
     * on the map object with the name from the Name property of the attribute
     * object and the value from the Value property of the attribute object.
     *
     * @param {Array} attributes - An array of attribute objects.
     * @return {Object} - A map of attributes.
     */
    AttributesMap(attributes) {
      // Create an empty object to store the attributes
      let map = {};

      // Loop through each attribute object in the array
      attributes.forEach((element) => {
        // Create a new property on the map object with the name from the
        // Name property of the attribute object and the value from the
        // Value property of the attribute object.
        map[element.Name] = element.Value;
      });

      // Return the map of attributes
      return map;
    },

    /**
     * allGames - Returns true if the current game is "All games".
     * @return {boolean} - True if the current game is "All games".
     */
    allGames() {
      return this.game == "" || this.game == allGames;
    },
  },

  watch: {
    // Watch for changes in the game property
    game: function () {
      // If switch beatween games (exclude All games)
      if (
        !(
          this.game != this.gamePrev &&
          (this.game == allGames || this.gamePrev == allGames)
        )
      ) {
        this.getNextPage(this.filter, true);
      }

      this.gamePrev = this.game;
    },
  },
};
</script>
