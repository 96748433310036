<!-- List of server commands. -->
<template>
  <div>
    <h1>Commands</h1>
    Number of commands: {{ commands.length }}<br />
    <br />
    <div v-for="(cmd, index) in commands" :key="index">
      <h4 class="command text-success">{{ cmd.command }}</h4>
      <div class="descr">{{ cmd.descr }}</div>
      <div class="params" v-if="cmd.params"><i>parameters:</i> {{ cmd.params }}</div>
      <div class="processIn"><i>processing in:</i> {{ cmd.processIn }}</div>
      <br />
    </div>
  </div>
</template>

<script>
const cmdCommandsJson = "commjson";

export default {
  name: "CommandsPage",

  data() {
    return {
      commands: [],
    };
  },

  mounted() {
    let that = this;

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }
      switch (gw.command) {
        case cmdCommandsJson:
          that.commands = JSON.parse(data);
          console.debug(that.commands);
          break;
      }
    });

    // Send 'commands' command to WebRTC server
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdCommandsJson);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },
};
</script>

<style scoped>
.command {
  font-weight: bold;
}
</style>
