<template>
  <div>
    <!-- Action collapse content -->
    <div
      class="collapse mt-3 mb-3"
      :class="show ? 'show' : ''"
      id="collapseActionInput"
    >
      <!-- Card -->
      <div class="card">
        <!-- Header -->
        <div class="card-header">
          <div class="row">
            <!-- Title -->
            <h3 class="col">Actions</h3>

            <!-- Close button -->
            <div class="col p-2 text-end">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                data-bs-toggle="collapse"
                data-bs-target="#collapseActionInput"
              ></button>
            </div>
          </div>
        </div>

        <!-- Body -->
        <div class="card-body">
          <div class="description">
            This is new action group for selected game and filter.
          </div>
          <div
            v-for="(action, index) in actions"
            :key="index.id"
            class="input-group"
          >
            <!-- Add item input group -->
            <div class="input-group mb-3">
              <!-- Dropdown type of filter -->
              <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ action.filterType }}
              </button>
              <ul class="dropdown-menu">
                <li v-for="(type, index) in inventoryTypes" :key="index">
                  <a class="dropdown-item" @click="action.filterType = type">{{
                    type
                  }}</a>
                </li>
              </ul>

              <!-- Filter input -->
              <input
                type="text"
                class="form-control"
                placeholder="terms of action: ItemId += 10"
                v-model="action.filterAction"
              />

              <!-- Delete input button  -->
              <button
                :disabled="actions.length <= 1"
                class="btn btn-outline-secondary"
                type="button"
                id="removeButton"
                @click="removeAction(index)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>

          <!-- Buttons -->
          <div class="form-group">
            <!-- Add new input button  -->
            <button
              class="btn btn-secondary mr-2"
              type="button"
              id="addButton"
              @click="addAction()"
            >
              Add
            </button>

            <!-- Execute button -->
            <button
              class="btn btn-secondary float-end"
              type="button"
              id="executeButton"
              @click="sendToServer()"
            >
              Execute
            </button>
          </div>

          <!-- Answer message -->
          <div v-if="state.state != 0" class="float-end mt-3">
            <div v-if="state.state == 1" class="executing text-warning">
              {{ state.executing }}
            </div>
            <div v-if="state.state == 2" class="answer text-success">
              Action id
              <a :href="'#/action_list/' + actionId">{{ actionId }}</a>
              {{ state.answer }}.
            </div>
            <div v-if="state.state == 3" class="error text-danger">
              {{ state.error }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action collapse button -->
    <div class="input-group">
      <!-- Add action button -->
      <div
        class="btn btn-outline-secondary border hide-expanded collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseActionInput"
        :aria-expanded="show"
        aria-controls="collapseActionInput"
      >
        Action <i class="bi bi-file-earmark-plus-fill"></i>
      </div>

      <!-- Action Id input -->
      <input class="form-control" type="text" v-model="actionId" />

      <!-- Actions list button -->
      <!-- Href to action groups list or to list of actions if action id is set -->
      <a
        class="btn btn-secondary"
        :href="actionId ? '#/action_list/' + actionId : '#/action_groups'"
      >
        <i class="bi bi-list-ul"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";

const cmdUsersAction = "users.action/";

export default {
  name: "UsersAction",

  props: {
    filter: String, // Marshalled search filter
    game: String, // Game name
  },

  data() {
    return {
      actions: [{ id: 1, filterType: "Items", filterAction: "" }],
      inventoryTypes: ["Items", "Storage"],
      nextId: 2,
      state: {
        state: 0, // 0 - not executing, 1 - executing, 2 - done, 3 - error
        executing: "Action is executing.",
        answer: "sucessfully executed",
        error: "err",
      },
      show: false,
      actionId: "",
    };
  },

  mounted() {
    let that = this;

    // Get actions show state from local storage
    this.getActionsShow();

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      // Process answer to cmdUsersAction command
      switch (gw.command) {
        case cmdUsersAction + that.game + "/" + that.actionId: {
          that.processAnswer(gw.err, data);
          break;
        }
      }
    });
  },

  beforeUnmount() {
    // Save actions show state to local storage
    this.setActionsShow();
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    /** Add new action to actions array */
    addAction() {
      this.actions.push({
        id: this.nextId++,
        filterType: "Items",
        filterAction: "",
      });
    },

    /** Remove last action from actions array */
    removeAction(index) {
      if (this.actions.length <= 1) {
        return;
      }
      this.actions.splice(index, 1);
    },

    /** Send cmdUsersAction command */
    sendToServer() {
      this.state.state = 1;
      this.sendCmdUsersAction(this.actions);
    },

    /** Send cmdUsersAction command */
    sendCmdUsersAction() {
      // Make actions array string
      let actionArr = "";
      this.actions.forEach((action) => {
        if (actionArr != "") {
          actionArr += ", ";
        }
        actionArr += action.filterType + ": " + action.filterAction;
      });

      // Make request containing filter and actions array
      let actionReq = this.filter + "; " + actionArr;

      // Send request
      this.actionId = uuid.v1();
      let cmd = cmdUsersAction + this.game + "/" + this.actionId;
      this.teoweb.sendCmd(cmd, actionReq);
    },

    /** Process answer to cmdUsersAction command */
    processAnswer(err, data) {
      // Process error
      if (err) {
        this.state.state = 3;
        this.state.error = err;
        return;
      }

      // Process answer
      let answer = data;
      console.debug("got users.action answer:", answer);
      this.state.state = 2;
    },

    /** Get actions show state from local storage */
    getActionsShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      if (usersFilterStr) {
        let usersFilter = JSON.parse(usersFilterStr);
        if (usersFilter.actions === true || usersFilter.actions !== false) {
          this.show = true;
        } else {
          this.show = false;
        }
      }
    },

    /** Save actions show state to local storage */
    setActionsShow() {
      let usersFilterStr = localStorage.getItem("usersFilter");
      let usersFilter = { actions: this.show };
      if (usersFilterStr) {
        usersFilter = JSON.parse(usersFilterStr);
      }

      const collapseActionsClasses = document.getElementById(
        "collapseActionInput"
      ).classList;
      usersFilter.actions = collapseActionsClasses.contains("show");

      localStorage.setItem("usersFilter", JSON.stringify(usersFilter));
    },
  },
};
</script>

<style scoped>
.mr-2 {
  margin-right: 0.5rem !important;
}
.hide-expanded[aria-expanded="true"] {
  display: none;
}
</style>
