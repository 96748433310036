<template>
  <div>
    <hr class="norm" />
    <div>
      This application is based on
      <a
        href="https://github.com/teonet-go#teonet-v5"
        target="_blank"
        rel="noopener"
        >Teonet</a
      ><br />
      <br />
      Teonet is designed to create client-server systems and build networks for
      server applications operating within a microservice architecture. To do
      this, Teonet creates a network / cloud transport between its members. This
      transport uses UDP for communication between network peers. UDP packets
      are encrypted with unique keys. Teonet uses its own UDP-based protocol
      called Teonet Reliable UDP (TRU) for real-time communication, which allows
      low latency messages to be sent and protocol reliability features.
    </div>
  </div>
</template>

<style>
hr.norm {
  margin-top: 20px;
}
</style>
