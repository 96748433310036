<template>
  <!-- Action group view -->
  <div>
    <div class="mb-1" @click="select('#/action_list/' + action.ID)">
      <h1 v-if="top" class="mb-0">{{ action.Name || "Action" }}</h1>
      <h5 v-else class="mb-0">{{ action.Name || "Action" }}</h5>
      <div class="description mb-0 text-secondary">
        <div>Id: {{ action.ID }}</div>
        <div>Date: {{ formatDate(action.Date) }}</div>
      </div>
    </div>
    <div class="description">
      <div>Dadmin user: {{ action.User || "Unknown" }}</div>
      <div>
        Reason:
        {{ action.Reason || "It was urgently necessary" }}
      </div>
      <div>Game: {{ action.Game || "All games" }}</div>
      <div>Filter: {{ action.Filter || "Not set" }}</div>
      <div>
        Performing an action:
        {{ action.Actions != "" ? action.Actions : getActionsLength() }}
      </div>
    </div>
  </div>
</template>

<script>
const cmdGroupLength = "journal.list.length/";

export default {
  name: "ActionGroup",

  props: {
    action: Object,
    top: Boolean,
  },

  mounted() {
    let that = this;

    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      switch (gw.command) {
        case cmdGroupLength + that.action.ID:
          that.action.Actions = JSON.parse(data);
          break;
      }
    });
  },

  unmounted() {
    this.teoweb.delReader(this.reader);
  },

  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    select: function (url) {
      window.location.href = url;
    },

    /** Request number of actions in action group */
    getActionsLength() {
      this.teoweb.sendCmd(cmdGroupLength + this.action.ID);
    },
  },
};
</script>
