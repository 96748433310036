<template>
  <span>
    {{ users.lenght }}
  </span>
</template>

<script>
export default {
  name: "PageUsers",

  data() {
    return {
      users: {
        lenght: 0, // Number of users
      },
    };
  },

  mounted() {
    let that = this;
    const cmdLenght = "users.length";

    // Add 'reader' which will receive data from WebRTC Data Channel
    this.reader = this.teoweb.addReader(function (gw, data) {
      if (gw.err) {
        return;
      }

      // Process answer to cmdLenght command
      // if (gw.command.startsWith(cmdHello)) {
      //   console.debug("got hello answer:", data);
      // }
      switch (gw.command) {
        case cmdLenght:
          that.users.lenght = JSON.parse(data);
          break;
      }
    });

    // Send cmdLenght command with parameter name to WebRTC server
    this.teoweb.whenConnected(function () {
      that.teoweb.sendCmd(cmdLenght);
    });
  },

  unmounted: function () {
    this.teoweb.delReader(this.reader);
  },
};
</script>
