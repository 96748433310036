import { createApp } from 'vue'
import App from './App.vue'

import teoweb from 'teoweb' // to use teonet webrtc connection
import 'bootstrap/dist/css/bootstrap.css' // to use bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.js' // to use bootstrap JS
import 'bootstrap-icons/font/bootstrap-icons.css'; // to use bootstrap icons
import './bootstrap_theme_tolge.js' // to use bootstrap color theme tolge

let server_name = "dadmin-server";
try {
    let s = require('./server_name.js')
    // 
    console.debug("got server_name from file:", s.server_name)
    server_name = s.server_name
} catch (error) {
    //
    console.debug("got server_name default:", server_name)
}

const app = createApp(App)
app.config.globalProperties.teoweb = teoweb();
app.config.globalProperties.server_name = server_name;
// The f function will be called when frontend connected to webrtc server and
// login token was checked.
app.config.globalProperties.teoweb.whenLogin = function (f) {
    let teoweb = app.config.globalProperties.teoweb;
    teoweb.whenConnected(function () {
        let ff = function () {
            if (teoweb.token != null) {
                f();
                return;
            }
            setTimeout(() => {
                ff();
            }, 5);
        }
        ff();
    });
}
app.mount('#app')